<template>
  <div class="classWork">
    <div class="chat-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`@/assets/image/course.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">职业规划课程体系</div>
      </div>
      <div class="head-right">
        <el-image
          class="backIcon"
          @click="sendExit"
          :src="require(`@/assets/image/exit2.png`)"
          fit="contain"
        ></el-image>
      </div>
    </div>

    <el-row class="search">
      <el-col :span="4">
        <el-row type="flex">
          <el-col :span="8">
            <el-image
              class="backIcon"
              @click="goBack"
              :src="require(`@/assets/image/backIcon.png`)"
              fit="contain"
            ></el-image
          ></el-col>
          <el-col :span="16">{{ entrepot_name }}</el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between" class="work">
      <el-col :span="8" class="workTitleList">
        <el-row>
          <el-col :span="24" class="totalText"
            >题量{{ totalQuantity }}，总分{{ totalPoints }}</el-col
          >
          <el-col
            :span="24"
            v-for="item in detailData"
            :key="item.type_order + item.data_type"
          >
            <h4 v-if="item.type_order === 1" class="topicTitle">
              <div>
                {{ REVERSERADIOTYPE[item.data_type] }}(共{{
                  typeTotalObj[item.data_type] &&
                  typeTotalObj[item.data_type].num
                }}题，{{
                  typeTotalObj[item.data_type] &&
                  typeTotalObj[item.data_type].total
                }}分)
              </div>
              <div class="finish">
                <i class="el-icon-check" v-show="item.isAllDone"></i>
              </div>
            </h4>
            <div
              v-if="item.type_order > 0"
              class="topic"
              @click="onCheckedItem(item)"
            >
              <div>{{ item.type_order }}、</div>
              <div class="questionText">{{ item.data_questionText }}</div>
              <div>({{ item.data_score }})分</div>
              <div class="finish">
                <i class="el-icon-check" v-show="item.isDone"></i>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="16" class="workContent">
        <div>
          <h3>
            {{ REVERSERADIOTYPE[checkProblem.data_type] }}(共{{
              typeTotalObj[checkProblem.data_type] &&
              typeTotalObj[checkProblem.data_type].num
            }}题，{{
              typeTotalObj[checkProblem.data_type] &&
              typeTotalObj[checkProblem.data_type].total
            }}分)
          </h3>
          <p>
            {{ checkProblem.type_order }}、{{
              checkProblem.data_questionText
            }}（{{ checkProblem.data_score }}）分
          </p>
          <!-- 单选题或者判断题 -->
          <el-radio-group
            v-model="checkProblem.job_analysis"
            v-if="checkProblem.data_type === 1 || checkProblem.data_type === 4"
          >
            <div
              class="option"
              v-for="item in checkProblem.option"
              :key="item.shop_user_data_option_id"
            >
              <el-radio :label="item.shop_user_data_option_id">{{
                item.option_content
              }}</el-radio>
            </div>
          </el-radio-group>
          <!-- 多选题 -->
          <el-checkbox-group
            v-model="checkProblem.job_analysis"
            v-if="checkProblem.data_type === 2"
          >
            <div
              class="option"
              v-for="item in checkProblem.option"
              :key="item.shop_user_data_option_id"
            >
              <el-checkbox :label="item.shop_user_data_option_id">{{
                item.option_content
              }}</el-checkbox>
            </div>
          </el-checkbox-group>
          <!-- 填空||解答题 -->
          <el-input
            v-if="checkProblem.data_type === 3 || checkProblem.data_type === 5"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 10 }"
            placeholder="请输入内容"
            v-model="textarea"
          >
          </el-input>
        </div>
      </el-col>
    </el-row>

    <div class="bottom">
      <el-button class="submitWork" type="primary" @click="onSubmitWork">
        保存作业
      </el-button>
    </div>
  </div>
</template>

<script>
import { getMyEntrepotDetail, insertDataJob } from "@/request/api";
export default {
  data() {
    return {
      detailData: [],
      entrepot_name: "", // 作业名
      totalQuantity: 0, // 题量
      totalPoints: 0, // 总分
      REVERSERADIOTYPE: {
        1: "单选题",
        2: "多选题",
        3: "填空题",
        4: "判断题",
        5: "简答题",
      },
      typeTotalObj: {},
      checkProblem: {}, // 选中的题目
    };
  },
  watch: {
    "checkProblem.job_analysis"() {
      const list = this.detailData.map((item) => {
        // 当前题的判断
        if (item.shop_user_data_id === this.checkProblem.shop_user_data_id) {
          if (
            !this.checkProblem.job_analysis ||
            this.checkProblem.job_analysis.length === 0 ||
            this.checkProblem.job_analysis === ""
          ) {
            item.isDone = false;
          } else {
            item.isDone = true;
          }
        }

        // 当前题型的判断
        item.isAllDone = true;
        this.detailData.forEach((value) => {
          if (item.data_type === value.data_type) {
            if (
              !value.job_analysis ||
              value.job_analysis.length === 0 ||
              value.job_analysis === ""
            ) {
              item.isAllDone = false;
            }
          }
        });

        return item;
      });
      this.detailData = list;
      // this.
    },
  },
  methods: {
    async onSubmitWork() {
      const workList = [...this.detailData];
      const body = {
        isNew: true,
        shop_user_class_entrepot_job_id:
          this.$route.query.shop_user_class_entrepot_job_id,
        data: workList.map((item) => {
          const newItem = { ...item };
          if (newItem.job_analysis instanceof Array) {
            newItem.job_analysis = newItem.job_analysis.join(",");
          }
          return {
            shop_user_data_id: newItem.shop_user_data_id,
            job_analysis: newItem.job_analysis,
            job_score: "",
          };
        }),
      };
      const res = await insertDataJob(body);
      if (res.data.code === "1000") {
        this.$message.success("保存成功");
        this.init(this.$route.query.shop_user_class_entrepot_job_id);
      }
    },
    goBack() {
      this.$router.push("classWork");
    },
    sendExit() {
      this.$emit("update-value");
    },
    onCheckedItem(item) {
      this.detailData = this.detailData.map((value) => {
        if (this.checkProblem.shop_user_data_id === value.shop_user_data_id) {
          value = { ...this.checkProblem };
        }
        return value;
      });
      this.checkProblem = item;
    },
    init(shop_user_class_entrepot_job_id) {
      getMyEntrepotDetail(shop_user_class_entrepot_job_id).then((res) => {
        if (res.data.code === "1000") {
          this.detailData = res.data.data.data;
          this.totalQuantity = res.data.data.data.length;
          this.totalPoints = res.data.data.data.reduce((prev, curr) => {
            return prev + curr.data_score;
          }, 0);

          let newTypeTotalObj = {}; // 每种题型的总分
          let totalP = 0; // 总分
          this.detailData = this.detailData.map((item) => {
            // const lastItem = this.detailData[index > 0 ? index - 1 : 0];

            if (item.data_score) {
              totalP += item.data_score; // 所有总分
              // 计算每种题型的总分

              newTypeTotalObj[item.data_type] = newTypeTotalObj[item.data_type]
                ? newTypeTotalObj[item.data_type]
                : { total: 0, num: 0 };
              newTypeTotalObj[item.data_type].total =
                newTypeTotalObj[item.data_type].total + item.data_score;
              newTypeTotalObj[item.data_type].num =
                newTypeTotalObj[item.data_type].num + 1;
            }

            const result = item.data_question
              .match(/>([^<]+)</g)
              .map((s) => s.slice(1, -1).trim());
            item.data_questionText = result[0];

            if (item.data_type === 2) {
              if (item.job_analysis) {
                item.job_analysis = item.job_analysis
                  .split(",")
                  .map((item) => item * 1);
              } else {
                item.job_analysis = [];
              }
            }
            if (item.data_type === 1 || item.data_type === 4) {
              item.job_analysis = item.job_analysis * 1;
            }
            if (
              !item.job_analysis ||
              item.job_analysis.length === 0 ||
              item.job_analysis === ""
            ) {
              item.isDone = false;
            } else {
              item.isDone = true;
            }
            return item;
            // 插入每种类型的标题
            // if (item.type_order === 1) {
            //   if (!lastItem.data_title) {
            //     this.detailData.splice(index > 0 ? index : 0, 0, {
            //       data_type: this.checkProblem.data_type,
            //       type_order: 0,
            //       data_title: true,
            //     });
            //   }
            // }
          });
          this.totalPoints = totalP;
          this.typeTotalObj = newTypeTotalObj;
          this.checkProblem = this.detailData[0];
        }
      });
    },

    conversion(time) {
      const date = new Date(time);
      const Y = date.getFullYear();
      const M = date.getMonth() + 1;
      const D = date.getDate();
      return Y + "-" + M + "-" + D;
    },
  },
  mounted() {
    this.entrepot_name = this.$route.query.entrepot_name;
    this.init(this.$route.query.shop_user_class_entrepot_job_id);
  },
};
</script>

<style lang="less" scoped>
.classWork {
  height: 100%;
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      display: flex;
    }
    .myWork {
      width: 90px;
      line-height: 26px;
      cursor: pointer;
      color: #2a6ee9;
      font-size: 16px;
    }
    .backIcon {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }

  .work {
    height: 66%;
  }
  .search {
    width: 100%;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    padding: 20px 40px;
    margin: 30px 0;
    line-height: 38px;
    // font-size: 18px;
    .backIcon {
      width: 34px;
      height: 34px;
      cursor: pointer;
    }
    .searchRadio {
      font-size: 18px;
    }
  }

  .workTitleList {
    width: 31%;
    padding: 20px;
    border-radius: 8px 8px 8px 8px;
    background: #fff;
    // margin-right: 20px;
    overflow-y: auto;
    .totalText {
      font-weight: 400;
      font-size: 14px;
      color: #929292;
    }
    .topicTitle {
      display: flex;
      margin: 10px 0;
    }
    .topic {
      font-size: 14px;
      color: #61a0ff;
      font-weight: 400;
      line-height: 24px;
      cursor: pointer;
      display: flex;
    }
    .questionText {
      font-size: 14px;
      width: 60%;
      overflow: hidden;
      text-overflow: ellipsis; //文本溢出显示省略号
      white-space: nowrap; //文本不会换行
    }
    .finish {
      border: 1px solid #4064ef;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      margin: 2px 0 0 10px;
      color: #61a0ff;
    }
  }

  .workContent {
    padding: 20px;
    background: #fff;
    border-radius: 8px 8px 8px 8px;
    .option {
      margin: 10px;
    }
  }
  .bottom {
    padding: 20px 0;
    background: #fff;
    text-align: end;
    box-shadow: 0px -3px 3px 1px rgba(41, 90, 255, 0.16);
    margin-top: 15px;
    border-radius: 8px 8px 8px 8px;
    .submitWork {
      margin-right: 50px;
    }
  }
}
</style>
